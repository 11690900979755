<template>
  <main class="page">
    <section class="section section--auth c-reset c-auth">
      <div class="auth-container">
        <form @submit="submitChangePassword" novalidate>
          <div class="form__header mb-4">
            <div class="form__title">
              <h1 class="title title-lg">
                {{ $t('web.change_password') }}
              </h1>
            </div>
          </div>
          <div class="form__body">
            <div class="form__group">
              <div class="form__item">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.CURRENT_PASSWORD"
                  :label="$t('web.current_password')"
                  autocomplete="on"
                  v-model="inputEmail"
                  :type="$CONSTANTS.INPUT.META.TYPE.PASSWORD"
                  :validateMethods="
                    validateMethods[CONSTANTS.LOCAL.REFS.CURRENT_PASSWORD]
                  "
                ></c-input>
              </div>
            </div>
            <div class="form__group">
              <div class="form__item">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.NEW_PASSWORD"
                  :label="$t('web.new_password')"
                  autocomplete="on"
                  v-model="inputPassword"
                  :type="$CONSTANTS.INPUT.META.TYPE.PASSWORD"
                  :validateMethods="
                    validateMethods[CONSTANTS.LOCAL.REFS.NEW_PASSWORD]
                  "
                ></c-input>
              </div>
            </div>
            <div class="form__group">
              <div class="form__item">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.CONFIRM_PASSWORD"
                  :label="$t('web.confirm-password')"
                  v-model="inputPasswordConfirm"
                  autocomplete="on"
                  :type="$CONSTANTS.INPUT.META.TYPE.PASSWORD"
                  :validateMethods="
                    validateMethods[CONSTANTS.LOCAL.REFS.CONFIRM_PASSWORD]
                  "
                ></c-input>
              </div>
            </div>
            <div class="form__group mt-3">
              <div class="form__item">
                <c-button
                  :value="$t('web.reset')"
                  :loading="cButtonDisabled"
                ></c-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import METHODS from '@/utils/Validator/METHODS'
import Validator from '@/utils/Validator'
import CInput from '@/components/Input'
import CButton from '@/components/Button'
import { normalizeText } from '@/utils/text'
const CryptoJS = require('crypto-js')

const _CONSTANTS = {
  REFS: {
    CURRENT_PASSWORD: 'current_password',
    NEW_PASSWORD: 'new_password',
    CONFIRM_PASSWORD: 'confirm_password',
  },

  VALIDATOR_METHODS: {
    COMPARE_PASSWORDS: 'comparePasswords',
  },
}

export default {
  name: 'ChangePassword',

  components: {
    CInput,
    CButton,
  },

  data() {
    this.CONSTANTS = Object.assign({}, this.$CONSTANTS, { LOCAL: _CONSTANTS })
    return {
      cButtonDisabled: false,
      inputEmail: '',
      inputPassword: '',
      inputPasswordConfirm: '',
    }
  },

  computed: {
    validateMethods: {
      get() {
        return {
          [_CONSTANTS.REFS.CURRENT_PASSWORD]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.Password')],
            METHODS.IS_PASSWORD,
          ],
          [_CONSTANTS.REFS.NEW_PASSWORD]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.Password')],
            METHODS.IS_PASSWORD,
          ],
          [_CONSTANTS.REFS.CONFIRM_PASSWORD]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.Password')],
            METHODS.IS_PASSWORD,
            _CONSTANTS.VALIDATOR_METHODS.COMPARE_PASSWORDS,
          ],
        }
      },
    },
  },

  beforeMount() {
    Validator.addNewMethod(
      _CONSTANTS.VALIDATOR_METHODS.COMPARE_PASSWORDS,
      () => {
        const password = this.$refs[_CONSTANTS.REFS.NEW_PASSWORD].getValue()
        const confirm_password =
          this.$refs[_CONSTANTS.REFS.CONFIRM_PASSWORD].getValue()
        return {
          success: password === confirm_password,
          error: {
            message: this.$t('passwords-not-equal'),
          },
        }
      }
    )
  },

  mounted() {
    const password = this.$route.query?.p
    if (password) {
      const bytes = CryptoJS.AES.decrypt(
        password,
        this.$store.getters['config/pwdSecret']
      )
      const decryptPassword = bytes.toString(CryptoJS.enc.Utf8)
      this.$refs[_CONSTANTS.REFS.CURRENT_PASSWORD].setValue(decryptPassword)
      this.$refs[_CONSTANTS.REFS.CURRENT_PASSWORD].showPassword()
      localStorage.removeItem('password')
    }
  },

  methods: {
    submitChangePassword(event) {
      event?.preventDefault()
      this.changePassword()
    },
    changePassword() {
      const inputs = Object.keys(this.validateMethods).reduce((acc, type) => {
        if (this.$refs[type]) {
          acc.push(this.$refs[type])
        }
        return acc
      }, [])
      const promises = inputs.map((input) => input.validate())
      Promise.all(promises)
        .then(() => {
          this.cButtonDisabled = true

          this.$api.auth
            .changePassword(
              this.$refs[_CONSTANTS.REFS.CURRENT_PASSWORD].getValue(),
              this.$refs[_CONSTANTS.REFS.NEW_PASSWORD].getValue(),
              this.$refs[_CONSTANTS.REFS.CONFIRM_PASSWORD].getValue()
            )
            .then(() => {
              this.$notify({
                group: 'message',
                type: 'success',
                duration: 4500,
                speed: 500,
                title: this.$t('web.notify_password-changed'),
              })
              this.$router.push({ name: this.$ROUTER.NAME.AUTH.LOGIN })
            })
            .catch((error) => {
              // Ужас. Нужно чтобы таких ифов не было
              if (error.errors.data) {
                this.$store.dispatch('app/showDialog', {
                  title: error.title,
                  type: 'error',
                  description: error.errors.data[0],
                })
              } else {
                const componentsMap = {
                  current_password:
                    this.$refs[_CONSTANTS.REFS.CURRENT_PASSWORD],
                  password: this.$refs[_CONSTANTS.REFS.NEW_PASSWORD],
                  password_confirmation:
                    this.$refs[_CONSTANTS.REFS.CONFIRM_PASSWORD],
                }
                Object.entries(error.errors).forEach((err) => {
                  componentsMap[err[0]].setError(normalizeText(err[1][0])) // Поработать с ошибками. Добавить валидатор
                })
              }
            })
            .finally(() => {
              this.cButtonDisabled = false
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
@import '../index';
</style>
